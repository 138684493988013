.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }
}

.desktop-drawer {
  position: absolute;
  left: 0px;
  display: none !important;
}
.main-sidebar {
  width: 100% !important;
  /* transition: 1s; */
}
@media (min-width: 1200px) {
  .desktop-drawer {
    display: block !important;
  }
  .main-sidebar.collapse {
    width: 70px !important;
    display: block !important;
    /* transition: 1s; */
  }
  .main-content.full-width {
    margin-left: 70px;
    max-width: 96.3% !important;
    flex: 0 0 100%;
    /* transition: 1s;
    transition-delay: 0.4s; */
  }
  .collapse.main-sidebar span {
    display: none !important;
  }
  .main-sidebar.collapse .nav .nav-item,
  .main-sidebar.collapse .nav .nav-link {
    text-overflow: unset !important;
  }

  .login-card {
    width: 30%;
    margin: 100px auto;
    padding: 50px;
  }
}

.main-navbar .navbar .navbar-nav .dropdown-menu {
  left: 0px;
}

.card {
  min-height: 768px;
}
.lead {
  font-size: 0.9rem;
}

h1,
.h1 {
  font-size: 2.052rem;
}

.fa {
  font-size: 19px;
  margin-right: 10px;
}
